import React from "react"
import styles from "./kurumsal.module.scss"
import Menubar from "../components/menubar/menubarEng"
import Year40 from "../img/40yil.svg"
import Footer from "../components/footer/footerEng"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import TopImage from "../img/about01.jpg"
import BottomImage from "../img/about02.jpg"
import Uretim1 from "../img/uretim1.jpg"
import Uretim2 from "../img/uretim2.jpg"
import Uretim3 from "../img/uretim3.jpg"
export default () => (
  <div className={styles.kurumsalContainer}>
    <div className={styles.leftE}></div>
    <div className={styles.centerSite}>
      <Menubar />
      <div className={styles.imageTopAboutContainer}>
        <img src={TopImage} alt="oyma" className={styles.imageTopAbout} />
        <div className={styles.verticalLineWhiteAbout}>
          <div className={styles.socialMediaIcons}>
            <a
              href="https://www.facebook.com/atiaahsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="1x"
                className={styles.ffIcon}
              />
            </a>
            <br />
            <a
              href="https://www.instagram.com/atiaahsap/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className={styles.igIcon}
              />
            </a>
          </div>
        </div>
        <span className={styles.topSpanAboutInt}>INTERIOR</span>
        <span className={styles.topSpanAbout}>
          ABOUT <br />
          ATIA
        </span>
        <span className={styles.topSpanAboutBottom}>
          ABOUT <span style={{ color: "#977851" }}>US</span>
        </span>
      </div>
      <div className={styles.soylem}>
        <p>
          Atia was established in 1997 as a 180 m 2 manufacturing workshop by
          Haluk İDER. After expanding its workplace to 600 m 2 in 2009, Atia
          took a further step in 2014 and acquired its 2500 m 2 office with wood
          workshop, polishing workshop, project office and CNC processing
          center.
        </p>
        <p>
          Atia offers spaces used properly and prioritize life quality and
          comfort of everyone who desire to be in distinctive ergonomic living
          spaces with a character of its own. Prominent with its application
          quality and innovative and different solutions to each and every
          detail, Atia Wood also offers interior design, project, application,
          and consultancy services thanks to its interior designers and
          technical staff.
        </p>
      </div>
      <div className={styles.uretimContainer}>
        <span className={styles.uretimTitle}>PRODUCTION</span>
        <div className={styles.uretimLines}>
          <div className={styles.eu1} />
          <div className={styles.uu1}>
            <img src={Uretim1} alt="uretim1" />
            <p>SPECIAL</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.uu2}>
            <img src={Uretim2} alt="uretim2" />
            <p>ERGONOMIC</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.uu3}>
            <img src={Uretim3} alt="uretim3" />
            <p>QUALITY</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.eu2} />
        </div>
      </div>
      <div className={styles.soylem}>
        <p>
          When Atia redefines living spaces, it pays attention that the
          materials it uses are compatible with international standards, are
          recyclable and eco-friendly. By continuing this sensitivity awareness
          with the seedlings the company has planted as an investment in both
          nature and the future, Atia also fulfils its social responsibilities.
        </p>
      </div>
      <div className={styles.bottomContainer}>
        <img src={BottomImage} alt="door" className={styles.bottomImageAbout} />
        <div className={styles.bottomContainerTextContainer}>
          <p className={styles.bottomContainerText}>
            The brand Atia with 36 years of experience and knowledge behind it,
            starting from apprenticeship, has reached its high level of quality
            today. In almost 40 years, Atia has thoroughly undertaken the
            responsibilities required by bilateral communication with and
            sincerity towards its customers and still adhere to them.
          </p>
        </div>
      </div>
      <div className={styles.year36Container}>
        <img src={Year40} alt="40 year" />
      </div>
      <Footer />
    </div>
    <div className={styles.rightE}></div>
  </div>
)
